@import "./config";

@import "ngx-bootstrap/datepicker/utils/scss/variables";

@import "@customcoding/core/stylesheets/styles";
@import "@customcoding/shared/stylesheets/styles";

//export $cuco-brand-color for @customcoding/shared and @customcoding/core
:root {
	--cuco-brand-color: #{$cuco-brand-color};
}

// External
app-external cuco-subheader {
	display: none !important;
}

.button-icon {
	vertical-align: baseline;
	font-size: 1rem;
}

// FullCalendar Fixes TODO: remove when fullcalendar is removed completely
.fc-button, .fc-button .fc-icon {
	color: $brand !important;
	background-color: $brand-light !important;
	font-weight: 600;
	border: 0 transparent !important;

	&:hover, &.fc-button-active, &:hover .fc-icon, &.fc-button-active .fc-icon {
		color: $brand-inverse !important;
		background-color: $brand !important;
	}

	&:disabled {
		/*color: $brand-inverse !important;
		color: $brand !important;
		background-color: $brand-light !important;
		 */

		background: $cuco-brand-color !important;
		color: theme-inverse-color() !important;

	}
}

td .fc-today:not(.fc-day-top) {
	background: rgba($brand, 0.1) !important;
}

.fc-button:active, .fc-button.fc-button-active {

	background: $cuco-brand-color !important;
	color: theme-inverse-color() !important;

}

.fc-title {
	display: inline;
	white-space: pre-wrap !important;
}

.fc-daygrid-event {
	white-space: break-spaces !important;
}

.fc-event, .fc-daygrid-event, .fc-timegrid-event, .fc-list-event, .fc-event-dot {
	border: 1px solid #ddd !important;
}

.cuco-fc-pointer {
	.fc-event:not(.cuco-fc-absence) {
		cursor: pointer;
	}
}

.marked-bg {
	background-color: lighten($cuco-brand-color, 34%) !important;
	//background-color: #FF69B4 !important;
}

/* table focus improvement */

p-table .p-datatable table .p-datatable-thead tr th.p-sortable-column:focus {
	box-shadow: none!important;
}


app-dashboard .container-fluid > .row{

  //background:red;
  display:block!important; /*overwrite bootstrap row*/

}


//Only for iSupport - Do not sync
.tooltip-inner {
  border-radius: 1rem;
}

.emailListTooltip{
  .tooltip-inner{
    max-width: none;
    width: auto;
  }
}



// Only for isupport
//app-dashboard .container-fluid > .row{
//
//	//background:red;
//	display:block!important; /*overwrite bootstrap row*/
//
//}


/* iSupport rounded Corners */

.dashboard-user-card{
	overflow:hidden;
}

kt-portlet.card{

	border-radius:1rem;

}




/* FIXES for all Dashboard
TODO: @Elia, copy to Core
 */

#kt_footer{

	z-index:1300!important;
}
p-table .p-datatable .p-paginator-bottom .p-dropdown {
	z-index: 1030 !important;
}

p-multiselect .p-multiselect-panel{

	z-index:1031!important;
}

/*temp solution */

@media screen and (max-width: 482px) {

	app-search-input {

		//display:none!important;
		min-width: 0!important;
		flex: 1;

	}

}

#kt_aside_menu.aside-menu .menu-nav{

	padding-top:0px;
}

#kt_aside.aside-left .hidden-when-aside-maximized{

	min-width:26px;

}

#kt_aside .brand{
	height: 60px;
	margin-top: 10px;
}

#kt_aside .brand .hidden-when-aside-minimized{

	max-height:70px!important;
}

/* TODO: check if needed in Modesk Core
.footer-fixed #kt_footer.footer{
	height:40px;
}

 */

/* workaround until modesk-core removes pr-8 from component */
app-profile-summary .pr-8 {

	padding-right: 0 !important;
}

//TODO: please look for action-buttons or td.actions (1x solution is enough)
.action-buttons .btn {
	margin-left: 0.5em;
	margin-top: 0.25em;
	margin-bottom: 0.25em;
}

p-table td.actions a.btn, p-table td.actions button.btn, {
		margin-left: 0.5em;
		margin-top: 0.25em;
		margin-bottom: 0.25em;
}


/* enables new line inside text */
.multi-line-text {
	white-space: pre-wrap;
}



/* Puts ellipsis for long text, use together with `white-space: no-wrap` or similar */
.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
}

/* fix for z-index dropdowns in table, TODO: remove here and add to modesk core */
p-table .p-datatable-auto-layout > .p-datatable-wrapper {

	overflow-x: visible !important;
}

p-table.caller-table{

	td {
		text-align: left !important;

	}

	.p-datatable table .p-datatable-tbody tr td {
		vertical-align: top;
		border-bottom: 1px dashed #EAECF3;
	}

	.p-datatable table .p-datatable-tbody tr:last-child td {
		border-bottom: none;
	}

}
.p-paginator .p-dropdown-panel {

	z-index: 1031 !important;
}

p-table .p-datatable .p-paginator-bottom .p-dropdown {
	z-index: 1030 !important;
}

p-multiselect .p-multiselect-panel{

	z-index:1031!important;
}

/*TEST FOR ModeskCore Package */

.p-datatable-wrapper {

	min-height: 200px;
}

p-table .p-datatable-auto-layout>.p-datatable-wrapper{
	overflow-x:visible !important;
}

.color-circle {
	height: 16px!important;
	width: 16px!important;
	min-height: 16px!important;
}

//table sticky test
/*
.p-datatable .p-datatable-thead > tr > th {
	position: -webkit-sticky!important;
	position: sticky!important;
	top: 5rem!important;
}

.p-datatable tr > th {
	top: 7rem!important;
}
*/

//equal number whitespace for all browsers
.number-prefix{
  padding-left:8px;
}
.invoice-short-table{

  .shimmer-column td:last-child{

    div.justify-content-start{
      justify-content: center!important;
    }

  }

}



/* Filemanager tests
*/

.p-tree .p-tree-container .p-treenode .p-treenode-content{

 position: relative;
}
.drop-section{

	//background:red;
	position:absolute;
	width:100%;
	height:100%;
	display:none;
	cursor:move;

}

.drop-section.active{

	display:block;

}

.folder-icon-closed:before{
	line-height: 1;
	content: '';
	display: block;
	background-size: 25px 25px;
	width:25px;
	height:25px;
	background-image: url('./assets/media/folder/folder.svg');
}

.folder-icon-open:before{
	line-height: 1;
	content: '';
	display: block;
	background-size: 25px 25px;
	width:25px;
	height:25px;
	background-image: url('./assets/media/folder/folder-open.svg');

}

.folder-icon-shared-closed:before{
	line-height: 1;
	content: '';
	display: block;
	background-size: 25px 25px;
	width:25px;
	height:25px;
	background-image: url('./assets/media/folder/shared-folder.svg');
}

.folder-icon-shared-open:before{
	line-height: 1;
	content: '';
	display: block;
	background-size: 25px 25px;
	width:25px;
	height:25px;
	background-image: url('./assets/media/folder/shared-open.svg');

}

// App-input-tag fixes TODO move to core
app-input-tag {
	.form-control {
		padding: 0.32rem 1rem;
	}
}

/* PATIENT PROFILE TEMP SOLUTION FOR NAV */

app-sub-navigation .navi .navi-item {

  .navi-link.active .navi-icon i{
    color: $cuco-brand-color;
  }

  .navi-link:hover .navi-icon i {
    color: $cuco-brand-color;
  }

  .navi-link:hover {
    color: $cuco-brand-color;
  }

  .navi-link.active .navi-text {
    color: $cuco-brand-color;
  }

  .navi-link:hover .navi-text {

    color: $cuco-brand-color;
  }
}

.p-datatable .p-datatable-header {
	background: white;
	border: none;
}




.cuco-loading-modal{

	.modal-content{
		width:auto;
		padding:1rem;
	}
}

.clone-item {
	//position: absolute;
	z-index: 1000; /* Ensure it's above other elements */
}

.cdk-custom-placeholder{

	height:0;
	//display:none;
}

tr.restricted-row{

  padding-left:5px;
  filter: blur(10px);

  td span{
    height:2.5rem;
    width:50px;
    background: rgba(215, 213, 213, 0.9);
    display:block;

    border-radius:10px;

    &.big{
      width:100%;
    }
    &.square{
      width:2.5rem;
    }
    &.small{
      width:15px;
    }
  }
}

/* TOOLTIPS */

.custom-tooltip-long {
	.tooltip-inner {
		max-width: 200px;
		width: 200px;
		//text-align: left;
	}
}
.invoice-status-toolip{
	.tooltip-inner {
		max-width: 280px;
		width: 280px;
	}

}

.no-margin-left{
	margin-left:0!important;
}


/* GLOBAL */

.label {

	//line-height:auto;
	vertical-align: middle;

}

/* styles if label self has trunctated text or if inner element has truncated text */
.label{

	max-width:100%;
	&.text-truncate {
		max-width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.text-truncate{

		max-width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

}

/* hide scrollbar */
/*
::-webkit-scrollbar {
	width: 0px;
	background: transparent;
}
*/
/* Z-INDEX FIXES */

kt-portlet-body .ng-dropdown-panel {
	z-index:99999!important;

}

app-offside-panel{
	z-index:9999!important;
}
ngb-popover-window{
	z-index:99999!important;
}
//if ng-select appendTo is set to body:
body > .ng-dropdown-panel {
	z-index:99999!important;
}

p-table .p-datatable .p-paginator-bottom .p-dropdown{
	z-index:auto!important;
}
/* safari scrollbar overlaying header fix */
#kt_header,#kt_subheader > .container-fluid{
	z-index:9999;
}

.modal{
	z-index: 99999;
}
.modal-backdrop{
	z-index:99999!important;
}

#kt_aside{
	z-index:9999!important;
}
/*customized scrollbar, only visible on hover: */
#kt_wrapper{
	height: 100vh;
	overflow-x: scroll;
	transition: scrollbar-color 0.3s ease-out;
	scrollbar-color:transparent transparent;
	scrollbar-width: thin;
}


#kt_wrapper > *::-webkit-scrollbar-track
{

	background-color:transparent;
}

#kt_wrapper > *::-webkit-scrollbar
{
	width: 6px;
	//background-color: #F5F5F5;
	background-color: transparent;
}

#kt_wrapper > *::-webkit-scrollbar-thumb
{
	background-color:transparent;
	//background-color: var(--cuco-brand-color)
}

#kt_wrapper:hover {


	scrollbar-color: #80808F #F5F5F5;


	& > *::-webkit-scrollbar-track{

		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: #F5F5F5;
	}
	& > *::-webkit-scrollbar {
		//width: 8px;
		background-color:#F5F5F5;
		//visibility:visible;
	}
	& > *::-webkit-scrollbar-thumb {
		//background-color: rgba(0,0,0,.2)
		background-color: #80808F;

		//visibility:visible;
	}
}

/*
@media (hover: none) {
	:host {
		&::-webkit-scrollbar {
			width:6px;
			//visibility:visible;
		}
		&::-webkit-scrollbar-thumb {
			visibility:visible;
			background-color:#B5B5C3;
			//background-color: rgba(0,0,0,.2)
		}
	}
}
*/

//fix for datepicker overlay z-index issue:

bs-datepicker-container{
	z-index:99999;
}

/* DEMO STYLES */

.demo-banner-grid {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas: 'noDemo' 'noDemo';
}

.demo-banner{
	height:50px;
	width:100%;
	background:#E1F0FF;
	z-index:10999;

	align-content: center;
	align-items: center;
	display:flex;
	padding-left:1rem;

	img{
		height:30px;
		width:auto;
	}

	span{
		color:#3699FF;
		font-weight:600;
	}

}

.demo-environment {

  grid-area: unset !important;

	app-aside-left{
		.aside-fixed.aside{
			top:50px;
		}
	}

	#kt_header{
		margin-top: 50px;
	}
	#kt_subheader{
		margin-top:50px;
	}

	#kt_quick_user{
		top:50px;
	}

	#kt_content{
		margin-bottom:50px;
	}

}




.custom-dropdown-panel {
	//max-width:400px;
	z-index:9999999!important;
	//transform: translateX(-200px);
	right:0!important;
	left:auto!important;

}

.tooltip{
	z-index:999999!important;
}
